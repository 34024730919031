/*document.addEventListener('turbo:load', function() {
    processPayment()
    
});*/
//горния код е закоментиран, за да спрем turbo и е заменен с този:
document.addEventListener("DOMContentLoaded", function () {
  processPayment();
});

function processPayment(exclamationCount) {
  // Тестване на jQuery
  $(document).ready(function () {});
  var totalAmount = 0;
  var orderPayments = [
    //  масив за предаване към контролера
  ];
  var paymentDoc = "";
  var docNumber = "";
  var requestData = JSON.stringify(orderPayments);
  document.addEventListener("DOMContentLoaded", function () {
    $(document).on("click", ".transferAmountButton", function () {
      var orderId = $(this).data("order-id");
      transferAmount(orderId);
    });

    $(".paymentInput").on("input", function () {
      var orderId = $(this).data("id");
      var orderNumber = $(this).data("order-number");
      var paymentDoc = $(this).closest("tr").find(".paymentDoc").val() || "";
      var docNumber = $(this).closest("tr").find(".docNumber").val() || "";
      orderNumber = parseInt(orderNumber);
      var orderRow = $('#orderTable tr[data-id="' + orderId + '"]');
      var paymentAmount = parseFloat($(this).val().replace(",", ".")) || 0;
      var price = parseFloat(orderRow.data("price"));
      // Задаване на оригиналната totalAmount преди промяната
      var originalTotalAmount = totalAmount;
      if ($(this).val().trim() === "") {
        paymentAmount = 0;
      }
      // Задаване на оригиналната цена преди промяната
      var originalPrice = parseFloat(orderRow.data("price"));
      if (
        !isNaN(paymentAmount) &&
        paymentAmount >= 0 &&
        paymentAmount <= price
      ) {
        var currentPayment = parseFloat(orderRow.data("payment")) || 0;
        totalAmount = totalAmount - currentPayment + paymentAmount;
        orderRow.data("payment", paymentAmount);
        var newPrice = price - paymentAmount;

        // Зануляване на сумата в колоната "Цена" при прехвърляне
        if (paymentAmount > 0) {
          newPrice = 0;
        }

        // Актуализираме "цена" според въведената сума в "плащане"
        actualPrice = price - paymentAmount;
        orderRow.find("td[data-price]").text(actualPrice.toFixed(2));
        var orderNumber = document.querySelector(
          `button[data-order-id="${orderId}"]`
        ).dataset.orderNumber;
        // Превръщане на orderNumber в число
        orderNumber = parseInt(orderNumber);
        $(document).ready(function () {
          // Функция, която активира или деактивира полетата за документ за плащане в зависимост от въведената сума
          function togglePaymentDoc() {
            $(".paymentDoc").each(function () {
              var paymentAmount =
                parseFloat(
                  $(this)
                    .closest("tr")
                    .find(".paymentInput")
                    .val()
                    .replace(",", ".")
                ) || 0;
              var paymentDocInput = $(this);
              if (paymentAmount > 0) {
                paymentDocInput.prop("disabled", false);
              } else {
                paymentDocInput.prop("disabled", true);
                // Изчистване на стойността в полето за документ за плащане
                paymentDocInput.val("");
                paymentDoc = paymentDocInput;
              }
            });
          }

          // Функция, която активира или деактивира полетата за номер на документ за плащане
          function toggleDocNumber() {
            $(".docNumber").each(function () {
              var paymentAmount =
                parseFloat(
                  $(this)
                    .closest("tr")
                    .find(".paymentInput")
                    .val()
                    .replace(",", ".")
                ) || 0;
              var docNumberInput = $(this);
              if (paymentAmount > 0) {
                docNumberInput.prop("disabled", false);
              } else {
                docNumberInput.prop("disabled", true);
                docNumberInput.val("");
              }
            });
          }

          // Извикване на функцията, която активира или деактивира полетата за документ за плащане в зависимост от въведената сума
          togglePaymentDoc();
          // Извикване на функцията, която активира или деактивира полетата за номер на документ
          toggleDocNumber();

          function autoFillFromFirstRow(currentRow) {
            // Вземи стойностите от първия ред
            var firstRow = $("table tbody tr:first");
            var firstPaymentDoc = firstRow.find(".paymentDoc").val() || "";
            var firstDocNumber = firstRow.find(".docNumber").val() || "";

            // Ако има стойности на първия ред и текущите полета са празни, попълни ги
            if (firstPaymentDoc && !currentRow.find(".paymentDoc").val()) {
              currentRow.find(".paymentDoc").val(firstPaymentDoc);
            }

            if (firstDocNumber && !currentRow.find(".docNumber").val()) {
              currentRow.find(".docNumber").val(firstDocNumber);
            }
          }
          // Събитие, което се извиква при въвеждане на данни в полетата за сума
          $(".paymentInput").on("input", function () {
            var paymentDoc =
              $(this).closest("tr").find(".paymentDoc").val() || "";
            var currentRow = $(this).closest("tr");
            var orderId = currentRow.data("id");
            var orderNumber = $(this).data("order-number");
            var paymentAmount =
              parseFloat(
                currentRow.find(".paymentInput").val().replace(",", ".")
              ) || 0;

            togglePaymentDoc(paymentDoc);
            toggleDocNumber();
            // Ако редът не е първият, извикай autofill
            if (!currentRow.is(":first-child")) {
              autoFillFromFirstRow(currentRow);
            }
            // Актуализирай масива orderPayments
            var paymentDoc = currentRow.find(".paymentDoc").val() || "";
            var docNumber = currentRow.find(".docNumber").val() || "";
            var orderIndex = orderPayments.findIndex(
              (order) => order.orderId === orderId
            );

            updateOrderPayments(
              orderId,
              paymentAmount,
              orderNumber,
              paymentDoc,
              docNumber
            );
          });

          // Събитие, което се извиква при въвеждане на данни в полетата за документ за плащане и номер на док.
          $(".paymentDoc, .docNumber").on("input", function () {
            //var paymentDoc = $(this).val() || ''; този код е когато се следи за събтие само в paymentDoc
            var paymentDoc =
              $(this).closest("tr").find(".paymentDoc").val() || "";
            var docNumber =
              $(this).closest("tr").find(".docNumber").val() || ""; // Вземете стойността от полето docNumber
            var orderId = $(this).closest("tr").data("id");
            var orderNumber = $(this).closest("tr").data("order-number");
            var paymentAmount =
              parseFloat(
                $(this)
                  .closest("tr")
                  .find(".paymentInput")
                  .val()
                  .replace(",", ".")
              ) || 0;
            // Проверка дали има въведена сума за плащане
            if (paymentAmount < 0.01) {
              $(this).prop("disabled", true);
              $(this)
                .closest("tr")
                .find(".paymentDocError")
                .text("Не може да въвеждате без посочена сума за плащане");
            } else {
              $(this).prop("disabled", false);
              $(this).closest("tr").find(".paymentDocError").text("");
            }
            var orderIndex = orderPayments.findIndex(
              (order) => order.orderId === orderId
            );
            if (orderIndex !== -1) {
              orderPayments[orderIndex].paymentAmount = paymentAmount;
              orderPayments[orderIndex].paymentDoc = paymentDoc;
            } else {
              orderPayments.push({
                orderId: orderId,
                paymentAmount: paymentAmount,
                orderNumber: orderNumber,
                paymentDoc: paymentDoc,
                docNumber: docNumber,
              });
            }

            updateOrderPayments(
              orderId,
              paymentAmount,
              orderNumber,
              paymentDoc,
              docNumber
            );
          });
        });

        updateOrderPayments(
          orderId,
          paymentAmount,
          orderNumber,
          paymentDoc,
          docNumber
        );
        // Събиране на стойностите от масива и актуализиране на totalAmount
        var totalFromPayments = orderPayments
          .map(function (payment) {
            return payment.paymentAmount;
          })
          .reduce(function (acc, paymentAmount) {
            return acc + paymentAmount;
          }, 0);
        $("#totalAmount").text(totalFromPayments.toFixed(2));
      } else {
        // Въвеждането е невалидно - например, изчистване полето или показвание съобщение за грешка
        $(this).val(""); // Изчистване на полето
        // Възстановяване на оригиналната цена при грешка
        orderRow.find("td[data-price]").text(originalPrice.toFixed(2));
        // Възстановяване на оригиналната totalAmount при грешка
        var greshka = price - actualPrice;
        totalAmount = originalTotalAmount - greshka;
        $("#totalAmount").text(totalAmount.toFixed(2));
        // Изтриване на последния елемент от масива, тъй като съответната поръчка не е валидна
        orderPayments.pop();
        // Събиране на стойностите от масива и актуализиране на totalAmount
        var totalFromPayments = orderPayments
          .map(function (payment) {
            return payment.paymentAmount;
          })
          .reduce(function (acc, paymentAmount) {
            return acc + paymentAmount;
          }, 0);
        $("#totalAmount").text(totalFromPayments.toFixed(2));
        alert(
          "Моля, въведете валидна сума. Сумата не може да е по-голяма от дължимата цена, както и да е отрицателна стойност"
        );
        //добавяне на друга логика
        paymentAmount = 0;
        updateOrderPayments(
          orderId,
          paymentAmount,
          orderNumber,
          paymentDoc,
          docNumber
        );
      }
    });

    function updateOrderPayments(
      orderId,
      paymentAmount,
      orderNumber,
      paymentDoc,
      docNumber
    ) {
      // Намери индекса на поръчката в масива
      var orderIndex = orderPayments.findIndex(
        (order) => order.orderId === orderId
      );

      if (orderIndex !== -1) {
        // Ако обектът вече е в масива, допълни стойностите
        orderPayments[orderIndex].paymentAmount = paymentAmount;
        orderPayments[orderIndex].docNumber = docNumber || ""; // Запази старото, ако няма ново

        orderPayments[orderIndex].paymentDoc =
          paymentDoc || orderPayments[orderIndex].paymentDoc; // Запази старото, ако няма ново

        // Ако сумата е 0, изтрий обекта
        if (paymentAmount === 0) {
          orderPayments.splice(orderIndex, 1);
        }
      } else {
        // Ако обектът все още го няма, добави нов
        orderPayments.push({
          orderId: orderId,
          paymentAmount: paymentAmount,
          orderNumber: orderNumber,
          paymentDoc: paymentDoc,
          docNumber: docNumber,
        });

        // Ако сумата е 0, изтрий обекта
        if (paymentAmount === 0) {
          orderPayments.splice(orderIndex, 1);
        }
      }
      // Форматирай данните в JSON и обнови скритото поле във формуляра
      var orderPaymentsJson = JSON.stringify(orderPayments);

      var form = document.querySelector('form[name="payment"]');
      var hiddenInput = form.querySelector('input[name="orderPaymentsJson"]');

      if (!hiddenInput) {
        hiddenInput = document.createElement("input");
        hiddenInput.type = "hidden";
        hiddenInput.name = "orderPaymentsJson";
        form.appendChild(hiddenInput);
      }

      hiddenInput.value = orderPaymentsJson;
    }
    function transferAmount(orderId) {
      var orderRow = $('#orderTable tr[data-id="' + orderId + '"]');
      var price = parseFloat(orderRow.data("price"));
      // Задаване на въведената сума да бъде равна на цялата сума от колоната "Цена"
      orderRow.find(".paymentInput").val(price.toFixed(2)).trigger("input");
      // Повторно извикване на кода за обработка на въведената сума
      processPayment(1); // Примерно извикване на обработката на сумата
    }
    // Функция за обновяване на orderPaymentsJson и стойността на скритото поле
    function updateOrderPaymentsJson() {
      orderPaymentsJson = JSON.stringify(orderPayments);
      hiddenInput.value = orderPaymentsJson;
    }

    $("#paymentForm").on("submit", function (event) {
      event.preventDefault(); // Спираме стандартното изпращане на формата
      //console.log("Формата беше изпратена, но е спрян стандартният submit.");

      // Деактивираме бутона
      $("#paymentSubmitButton").prop("disabled", true);
      // Показваме съобщението за обработка
      $("#processingMessage").fadeIn();
      // Извличаме данните от формата
      var orderPaymentsJson = $("input[name='orderPaymentsJson']").val();
      // Функция за извличане на параметър от URL
      function getParameterByName(name) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(name);
      }
      // Вземаме customerId от URL
      var customerId = getParameterByName("customerId");
      // Проверка на извлечената стойност
      console.log("Customer ID:", customerId);
      console.log("orderPaymentsJson:", orderPaymentsJson);
      // Създаваме AJAX заявка
      var paymentUrl = "/payment/payables";
      $.ajax({
        url: paymentUrl,
        method: "POST",
        data: {
          orderPaymentsJson: orderPaymentsJson,
          customerId: customerId,
        },
        success: function (response) {
          // Може да нулираме полетата тук
          $(".paymentInput").val("");
          $(".paymentDoc").val("");
          $(".docNumber").val("");
          console.log($("#processingMessage").css("display"));
          $("#processingMessage").fadeOut(function () {
            // Това ще се извика след като fadeOut приключи
            $(this).css("display", "none");
            // Пренасочваме към друга страница
            window.location.href = "/order";
            alert("Данните бяха успешно обработени.");
          });
        },

        error: function (xhr, status, error) {
          $("#errorMessage").fadeIn(function () {
            location.reload(); // Презарежда страницата
            alert(
              "Възникна грешка при обработката на плащанията. Проверете данните и опитайте пак!"
            );
          });
        },
      });
    });

    function processPayment(exclamationCount) {}

    processPayment(3);
  });
}
module.exports = processPayment;
