// assets/controllers/custom-autocomplete_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    this._onPreConnect = this._onPreConnect.bind(this);
    this._onConnect = this._onConnect.bind(this);
  }

  connect() {
    console.log("Custom Autocomplete Controller connected!");
    this.element.addEventListener(
      "autocomplete:pre-connect",
      this._onPreConnect
    );
    this.element.addEventListener("autocomplete:connect", this._onConnect);
  }

  disconnect() {
    // You should always remove listeners when the controller is disconnected to avoid side-effects
    this.element.removeEventListener("autocomplete:connect", this._onConnect);
    this.element.removeEventListener(
      "autocomplete:pre-connect",
      this._onPreConnect
    );
  }

  _onPreConnect(event) {
    // Добавяне на персонализирани опции за Tom Select
    event.detail.options.placeholder = "Избер"; // Показва текст, когато няма избрана стойност
    event.detail.options.render = {
      item: function (data, escape) {
        return `<span>${escape(data.text)}</span>`; // Изобразява избраната стойност правилно
      },
    };
  }

  _onConnect(event) {
    // TomSelect has just been intialized and you can access details from the event
    //console.log("Tom Select instance:", event.detail.tomSelect); // TomSelect instance
    //console.log(event.detail.options); // Options used to initialize TomSelect
    // TomSelect е инициализиран и можеш да достъпиш инстанцията
    console.log("Tom Select instance:", event.detail.tomSelect);
    const tomSelect = event.detail.tomSelect;

    tomSelect.on("item_add", () => {
      const input = tomSelect.control_input;
      if (input) {
        input.style.display = "none"; // Скриваме input
      }
    });
  }
}
